import store from '../../store';
import Vue from 'vue';
import apiConfiguration from '../api/apiConfiguration';

export default class RailTosService {
	
	constructor(path, serviceName="rail") {
		this.path=path;
		this.BASE_PATH = apiConfiguration.BASEPATH[serviceName];
		this.mapEntityPath={
			RTBBinario:"binari",
			RTBCarro:"carri",
			RTBLogDanni:"logDanni",
			RTBMezzoMovimentazione: "mezziMovimentazione",
			RTBNodo: "nodi",
			RTBParametro: "parametri",
			RTBParcoBinari: "parcoBinari",
			RTBSoggetto: "soggetti",
			RTBTipoCarro:"tipoCarri",
			RTBTipoMezzoMovimentazione: "tipiMezzoMovimentazione",
			RTBTipoUti: "tipiUti",
			RTBTracciaTrenoStato:"",
			RTBTurno: "turni",
			RTBUti: "uti",
			RTTConvoglio: "convogli",
			RTTLavorazione: "lavorazioni",
			RTTTraccia:"tracce",
			RTTViaggioTreno:"viaggiTreno"
		};
    }

	list(startIndex, pageSize, sortBy, sortOrder, filters, countData){
		const fullPath = `${this.BASE_PATH}${this.path}/list`;
		const encFilters=encodeURIComponent(JSON.stringify(filters));
		return Vue.api.get(fullPath, {
			params: {
				start: startIndex, 
				length: pageSize, 
				sortField: sortBy, 
				sortOrder: sortOrder, 
				filters: encFilters, 
				countData: countData
			}
		});
	}

	get(id){
		const fullPath=`${this.BASE_PATH}${this.path}/${id}`;
		return Vue.api.get(fullPath);
	}

	//Deprecated
	edit(item, method){
		const fullPath = `${this.BASE_PATH}${this.path}${method}`;
		return Vue.api.post(fullPath, item);
	}

	post(item, method){
		const fullPath = `${this.BASE_PATH}${this.path}${method}`;
		return Vue.api.post(fullPath, item);
	}

	put(item, method){
		const fullPath = `${this.BASE_PATH}${this.path}${method}`;
		return Vue.api.put(fullPath, item);
	}

	add(item){
		const fullPath = `${this.BASE_PATH}${this.path}/add2`;
		return Vue.api.post(fullPath, {map: item});
	}

	delete(id){
		const fullPath=`${this.BASE_PATH}${this.path}/${id}`;
		return Vue.api.delete(fullPath);
	}
	//rail - deprecated
	getColumns(){
		const fullPath=`${this.BASE_PATH}${this.path}/getColumns`;
		return Vue.api.get(fullPath);
	}
	//yard
	getMetadata(){
		const fullPath=`${this.BASE_PATH}${this.path}/metadata`;
		return Vue.api.get(fullPath);
	}

	//lista per combobox (id, descrizione)
	//params:entity Nome entity
	getShortList(entity){
		const entityPath = this.mapEntityPath[entity] || entity;
		const fullPath = `${this.BASE_PATH}${entityPath}/shortList`;
		return Vue.api.get(fullPath);
	}

	getInfo(data){
		const entityPath = this.mapEntityPath[data.type] || data.type;
		const fullPath = `${this.BASE_PATH}${entityPath}/${data.id}`;
		return Vue.api.get(fullPath);
	}

	onError(err){
		console.log("Service error: ", err);
		return {success: false, message: err}
	}
}	
