<template >
  <div v-if="item" class="p-cardialog-content">
    <div class="p-grid p-fluid">
      <div class="p-col-12">
        <label for="viaggioIn">Viaggio In</label>
        <span class="viaggioLegend">(Codice | ETA | Provenienza)</span>
        <br />
        <Dropdown
          id="viaggioIn"
          v-model="item.viaggioInId"
          :options="listViaggiIn"
          optionValue="id"
          optionLabel="label"
          placeholder="Seleziona un valore"
        />
      </div>
      <div class="p-col-12">
        <label for="viaggioOut">Viaggio Out</label>
        <span class="viaggioLegend">(Codice | ETD | Destinazione)</span>
        <br />
        <Dropdown
          id="viaggioOut"
          v-model="item.viaggioOutId"
          :options="listViaggiOut"
          optionValue="id"
          optionLabel="label"
          placeholder="Seleziona un valore"
        />
      </div>

      <div class="p-col-6">
        <label for="eta">ETA</label>
        <br />
        <Calendar id="eta" v-model="item.eta" :showTime="true" dateFormat="dd/mm/yy" />
      </div>
      <div class="p-col-6">
        <label for="etd">ETD</label>
        <br />
        <Calendar id="etd" v-model="item.etd" :showTime="true" dateFormat="dd/mm/yy" />
      </div>
    </div>
  </div>
</template>

<script>
import RailTosService from "../../service/tos/RailTosService";
import moment from 'moment';

export default {
  data() {
    return {
      listViaggiIn: null,
      listViaggiOut: null
    };
  },
  service: null,
  props: ["item"],
  created() {
    this.service = new RailTosService("");
    this.adjustViaggio();
    this.retrieveList();
  },
  methods: {
    adjustViaggio() {
      this.item.eta=this.adjDate(this.item.eta);
      this.item.etd=this.adjDate(this.item.etd);
      this.item.processingTimeStart=this.adjDate(this.item.processingTimeStart);
      this.item.processingTimeEnd=this.adjDate(this.item.processingTimeEnd);
    },
    adjDate(date){
      if (date!=null) return new Date(date);
    },
    retrieveList() {
      this.listViaggiIn=[];
      this.listViaggiOut=[];
      this.$api.railData.getListViaggiConvoglio(this.item.id, this.item.viaggioInId).then(resp => {
        resp.forEach(element => {
          element.label=`${element.codice} | ${moment(element.data).format("YYYY-MM-DD HH:mm")} | ${element.nodo}`;
          let list = ("out"==element.tipo) ? this.listViaggiOut : this.listViaggiIn;  
          list.push(element);
        });
      });	
    }
  },
};
</script>

<style scoped>
.viaggioLegend{
  margin-left: 50px;
  font-weight: lighter;
  font-style: italic;
}
</style>